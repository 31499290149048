.bigcontainer,
.mainC {
/*  background: #08475E  !important;*/
}
.toprfl {
/*   background-image: url(src/images/bgtop.png); */
  background-color:#fff7ec;
	background: url(src/images/texture.png) center no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  border-bottom: 7px solid #C3922E;
  text-align: center;
}
.FootB {
  border-top: 7px solid #C3922E;
}

.topbanner {
  margin: auto;
}

.Fotbanner {
  width: 100%;
}
.bmtBtt {
  position: fixed;
  top: 85%;
  right: 25%;
  font-size: 18px;
}