.css-2xzavp {
background: rgb(7,56,92);
background: linear-gradient(45deg, rgba(7,56,92,1) 0%, rgba(34,155,172,1) 31%, rgba(17,95,122,1) 52%, rgba(32,148,166,1) 75%, rgba(14,82,112,1) 96%);

}

.css-klkb73 {
  margin: 0 auto 0px;
  text-align: center;
}

.css-1254965 {
  margin: 10px 0px 20px 0;
  background-color: #8E5A24;
  width: 100%;
  color: #ffffff;
}
.css-1jrsopm{background-color:#08475E !important}
.css-1yapxh, .css-11s5ovq{color:#08475e!important} 
.css-1nuums7.Mui-checked{color:#8e5a24 !important}

.haveyou {
  font-size: 19px;
}

.ifnot {
  font-size: 16px;
}

h1 {
  font-size: 30px;
}

/*next
.css-1gqib3n*/
/* 
  {} */

/*cancel
css-1kpbd96*/
.css-1l785x2 {
  background: #003f47;
}

.css-1hmiy9i {
  font-size: 26px;
}

.css-13tut6s {
  justify-content: center;
}

.css-1tfqlfq,
.css-13tut6s {
  margin: 0px 0px 0px 16px;
}

.css-13tut6s {
  padding: 25px;
}

.css-1gqib3n,  
.css-1kpbd96 {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.71429;
  font-size: 1rem;
  text-transform: capitalize;
  font-family: 'Public Sans', sans-serif;
  min-width: 80px;
  padding: 6px 16px;
  border-radius: 3px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: #8E5A24 !important;
  box-shadow: rgb(52 26 71 / 20%) 0px 8px 16px 0px;
  width: 250px;
  height: 58px;
}

.css-11s5ovq {
  text-align: left;
}

.top {
  background-image: url(src/images/bgtopbanner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  border-bottom: 7px solid #C3922E;
  text-align: center;
}
